import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { SelectContractContent, SelectContractTitle, SelectContractText, SelectContractCloseButton, SelectModifyButton, ConstrustBaseButton } from '../../css/CSS.js';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import useAuthAxios from "../../../useAuthAxios";
import { useNavigate } from 'react-router-dom';
import LoadingDialog from '../../Loading';
import Typography from '@mui/material/Typography';
import { DigitalConstractsContext } from "../../../DigitalConstracts";

export default function DigitalConstractSelectContract(props) {
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth"), };


    const [open, setOpen] = React.useState(false);
    const authAxios = useAuthAxios();
    const [contract, setContract] = useState()
    const [changeContract, setChangeContract] = useState(false)
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [onlyConstract, setOnlyConstract] = useState(false);
    const [selectBase, setSelectBase] = useState(false);
    const [selectModify, setSelectModify] = useState(false);
    const [constructsBase, setConstructsBase] = React.useState("");
    const [constructsModfiy, setConstructsModify] = React.useState("");
    const { setKojiCD, setSeqNo, setContractName, setContractType, setStatus, setStatusCheck } = useContext(DigitalConstractsContext);

    React.useLayoutEffect(() => {
        if (props.open === false) { return }
        if (props.open === true) {
            setStatusCheck(false)
            setProcessing(true)
            authAxios
                .get('api/contracts/contracts?'
                    + 'kojiCD=' + props.KojiCD + '&concluded=1'
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const listdata = JSON.parse((JSON.stringify(response.data)))
                        setContract(listdata)
                        if (listdata.contractList.length === 1) {
                            setOnlyConstract(true)
                        }
                        else {
                            setChangeContract(true)
                        }
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("契約書情報の取得に失敗しました。");
                    }
                });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    React.useLayoutEffect(() => {
        if (onlyConstract === true) {
            contract.contractList?.forEach((row) => {
                if (row.contractType === "工事請負契約書") { setSelectBase(true) }
                else if (row.contractType === "変更工事請負契約書") { setSelectModify(true) }
                setOnlyConstract(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlyConstract]);


    const handleClose = () => {
        setOpen(false);
        setConstructsBase("")
        setConstructsModify("")
        props.close(false)

    };


    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    React.useEffect(() => {
        if (changeContract === true) {
            contract.contractList?.forEach((row) => {
                if (row.contractType === "工事請負契約書") { setConstructsBase(row.status) }
                else if (row.contractType === "変更工事請負契約書") { setConstructsModify(row.status) }
            })
            setProcessing(false)
            setChangeContract(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeContract]);

    const handleSelectBase = () => {
        if (selectBase === true) { return }
        else {
            setSelectBase(true)
        }
    };

    React.useEffect(() => {
        if (selectBase === true) {
            contract.contractList?.forEach((row) => {
                if (row.contractType === "工事請負契約書") {
                    setKojiCD(row.kojiCD)
                    setSeqNo(row.seqNo)
                    setContractName(row.constructionName)
                    setContractType(row.contractType)
                    setStatus(row.status)
                    navigate("/digitalconstract?kojiCD=" + row.kojiCD + "&costructtype=" + (row.contractType === "工事請負契約書" ? "base" : "modfy") + "&seqno=" + row.seqNo);
                }
            })
            setStatusCheck(true)
            setSelectBase(false)
            setOpen(false)
            props.close(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectBase]);

    const handleSelectModify = () => {
        if (selectModify === true) { return }
        else {
            setSelectModify(true)
        }
    };

    React.useEffect(() => {
        if (selectModify === true) {
            contract.contractList?.forEach((row) => {
                if (row.contractType === "変更工事請負契約書") {
                    setKojiCD(row.kojiCD)
                    setSeqNo(row.seqNo)
                    setContractName(row.constructionName)
                    setContractType(row.contractType)
                    setStatus(row.status)
                    navigate("/digitalconstract?kojiCD=" + row.kojiCD + "&costructtype=" + (row.contractType === "工事請負契約書" ? "base" : "modfy") + "&seqno=" + row.seqNo);
                }
            })
            setStatusCheck(true)
            setSelectModify(false)
            setOpen(false)
            props.close(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectModify]);

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
            >
                <LoadingDialog load={processing} />
                <SelectContractTitle >
                    契約書選択
                </SelectContractTitle>
                <SelectContractContent>
                    <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={1} sx={{ pt: 3 }}>
                        <Grid item xs={12} >
                            <Grid container>
                                <Grid item xs={1.5} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    工事コード
                                </Grid>
                                <Grid item xs={1.1} style={{ backgroundColor: "#ffffff", fontSize: 20, display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <Typography sx={{ m: 1 }} style={{ fontSize: 20 }}>{props.KojiCD}</Typography>
                                </Grid>
                                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    邸名
                                </Grid>
                                <Grid item xs={8.4} style={{ backgroundColor: "#ffffff" }} >
                                    <Typography sx={{ ml: 1, mt: 1, mb: 1 }} style={{ fontSize: 20 }}>
                                        {props.ConstructionName === undefined ? contract?.contractList[0]?.constructionName : props.ConstructionName}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container columns={12} rowSpacing={{ xs: 1 }} columnSpacing={0} sx={{ pt: 3 }}>
                        <Grid item xs={8} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <ConstrustBaseButton type="button" onClick={() => handleSelectBase()} style={{ borderRadius: "5px", }}>
                                <Typography size="large" sx={{ ml: 22, mr: 22, mt: 2, mb: 2, fontSize: 25 }}>工事請負契約書</Typography>
                            </ConstrustBaseButton>
                        </Grid>
                        <Grid item xs={4} >
                            <SelectContractText style={{ backgroundColor: "#eaffea" }}>
                                <Typography style={{ fontSize: 20 }} sx={{ mt: 2.1, mb: 2.1, }}>{constructsBase === "" ? "未作成" : constructsBase} </Typography>
                            </SelectContractText>
                        </Grid>
                        <Grid item xs={8} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <SelectModifyButton type="button" size="large" onClick={() => handleSelectModify()} style={{ borderRadius: "5px", }}>
                                <Typography size="large" sx={{ ml: 18.5, mr: 18.5, mt: 2, mb: 2, fontSize: 25 }}>変更工事請負契約書</Typography>
                            </SelectModifyButton>
                        </Grid>
                        <Grid item xs={4}>

                            <SelectContractText style={{ backgroundColor: "#eaffea" }}>
                                <Typography style={{ fontSize: 20 }} sx={{ mt: 2.2, mb: 2.2, }}>{constructsModfiy === "" ? "未作成" : constructsModfiy}</Typography>
                            </SelectContractText>
                        </Grid>
                    </Grid>
                </SelectContractContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <SelectContractCloseButton type="button" onClick={handleClose}>キャンセル<KeyboardReturnIcon sx={{ ml: 1 }} /></SelectContractCloseButton>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}