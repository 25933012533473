import React, { useContext } from 'react';
import { ConstractContents, GrayButton, PointUpBackGround, ConstrustSearchButton } from '../../css/CSS.js';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DigitalConstractUploadFrame from './DigitalConstractUploadFrame'
import DigitalConstractUpdateFrame from './DigitalConstractUpdateFrame'
import DigitalConstractSelectContract from './DigitalConstractSelectContract'
import { useNavigate } from 'react-router-dom';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import SearchIcon from "@mui/icons-material/Search";
import { useForm, Controller } from "react-hook-form";
import LoadingDialog from '../../Loading';
import useAuthAxios from "../../../useAuthAxios";
import { DigitalConstractsContext } from "../../../DigitalConstracts";


export default function DigitalConstractEditFrame(props) {
    const authAxios = useAuthAxios();
    const navigate = useNavigate();
    const [InputKojiCD, setInputKojiCD] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);
    const [getContracts, setGetContracts] = React.useState(false);
    const [signaturesRequireNumber, setSignaturesRequireNumber] = React.useState();

    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth") };
    const {
        kojiCD, setKojiCD, seqNo, setSeqNo, constructionName, setContractName, contractType, setContractType,
        status, setStatus, statusCheck, setStatusCheck, setFromList
    } = useContext(DigitalConstractsContext);

    const handleChangeList = () => {
        navigate("/digitalconstract/list");
    }

    const validationRules = {
        kojiCD: {
            required: true,
            maxLength: { value: 5, message: '5桁以内で入力してください。' }
            , pattern: { value: /^[0-9a-zA-Z]+$/, message: '半角英数字のみで入力してください。' }
        },
    }

    const { control, handleSubmit, getValues, setValue, formState: { errors }, watch } = useForm({
        defaultValues: { kojiCD: kojiCD }
        , mode: 'onChange'
    });

    //メールから構成画面に直接遷移した場合の処理
    React.useLayoutEffect(() => {
        if (processing === true) { return; }
        if (localStorage.getItem("URLparams")) {
            setProcessing(true)
            setStatusCheck(false)

            const URLparams = JSON.parse(localStorage.getItem("URLparams"))
            if (URLparams.costructtype === "base") {
                setContractType("工事請負契約書")
            } else if (URLparams.costructtype === "modfy") {
                setContractType("変更工事請負契約書")
            }

            authAxios
                .get('api/contracts/contracts?'
                    + 'kojiCD=' + URLparams.kojiCD + '&concluded=1'
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const listdata = JSON.parse((JSON.stringify(response.data)))
                        listdata?.contractList
                            ?.forEach((row) => {
                                var URLparamsConstructType = URLparams.costructtype === "base" ? "工事請負契約書" : "変更工事請負契約書"
                                if (row.contractType === URLparamsConstructType) {
                                    setKojiCD(row.kojiCD)
                                    setValue('kojiCD', row.kojiCD)
                                    setSeqNo(row.seqNo)
                                    setContractType(URLparams.costructtype === "base" ? "工事請負契約書" : "変更工事請負契約書")
                                    setContractName(row.constructionName)
                                    setStatus(row.status)
                                }
                            })
                        localStorage.removeItem('URLparams')
                        setStatusCheck(true)
                    }
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("契約書情報の取得に失敗しました");
                    }
                    setProcessing(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("URLparams")]);

    const onSubmit = (action) => {
        //読込ボタン押下
        if (action === "handleReadSelectContractOpen") {
            if (watch("kojiCD") !== "" && Object.keys(errors).length === 0) {
                setInputKojiCD(getValues("kojiCD"))
                setGetContracts(true)
                setFromList(true)
            }
        }
    };

    //読込ボタン押下時アクション
    React.useEffect(() => {

        if (processing === true) { return; }
        if (getContracts === true) {
            setStatusCheck(false)
            authAxios
                .get('api/contracts/contracts?'
                    + 'kojiCD=' + InputKojiCD  + '&concluded=1'
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const listdata = JSON.parse((JSON.stringify(response.data)))
                        if (listdata.contractList === null) {
                            alert("契約書が見つかりません。工事コードをお確かめください。")
                        } else if (listdata.contractList.length === 1) {
                            setProcessing(true)
                            setKojiCD(listdata.contractList[0].kojiCD)
                            setSeqNo(listdata.contractList[0].seqNo)
                            setContractName(listdata.contractList[0].constructionName)
                            setContractType(listdata.contractList[0].contractType)
                            setStatus(listdata.contractList[0].status)
                            navigate("/digitalconstract?kojiCD=" + listdata.contractList[0].kojiCD + "&costructtype=" + (listdata.contractList[0].contractType === "工事請負契約書" ? "base" : "modfy") + "&seqno=" + listdata.contractList[0].seqNo);
                            setStatusCheck(true)
                        } else {
                            setOpen(true)
                        }
                    }
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("契約書情報の取得に失敗しました");
                    }
                    setProcessing(false)
                });
        }
        setGetContracts(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getContracts]);

    React.useEffect(() => {
        if (processing === true) { return; }
        if (statusCheck === true) {

            setProcessing(true)
            authAxios
                .get('api/contracts'
                    + "/" + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/info/mng"
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const listdata = JSON.parse((JSON.stringify(response.data)))
                        setSignaturesRequireNumber(listdata.statusDetal)
                        setStatus(listdata.status)
                    }
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("契約書管理情報の取得に失敗しました");
                    }
                    setProcessing(false)
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusCheck]);

    return (
        <ConstractContents sx={{ pt: 2 }} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <LoadingDialog load={processing} />
            <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                    <Grid container sx={{ pl: 2 }}>
                        <Grid item xs={8}>
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <Grid container >
                                    <Grid item md={2} lg={3} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: 17, ml: 1 }}>
                                            工事コード
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6.7} lg={5.6} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                        <Controller
                                            control={control}
                                            name="kojiCD"
                                            rules={validationRules.kojiCD}
                                            render={({ field, fieldState }) => (
                                                <TextField
                                                    {...field}
                                                    id="kojiCD"
                                                    inputProps={{ maxLength: 5, style: { fontSize: 25 } }}
                                                    variant="standard"
                                                    error={fieldState.invalid}
                                                    style={{ width: "100%" }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={3} lg={3} sx={{ mt: 0.4 }} >
                                        <ConstrustSearchButton type="submit"
                                            onClick={() => onSubmit("handleReadSelectContractOpen")}
                                            style={{
                                                width: "150px",
                                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}
                                        ><SearchIcon sx={{ mr: 1 }} />
                                            <Typography sx={{ fontSize: 20 }}>
                                                読込
                                            </Typography>
                                        </ConstrustSearchButton>
                                        <DigitalConstractSelectContract
                                            open={open}
                                            close={setOpen}
                                            KojiCD={InputKojiCD}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                        <Grid item md={4} lg={4} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            {localStorage.getItem("contractListOpe") === "1" ?
                                <GrayButton
                                    type="button"
                                    onClick={handleChangeList}
                                >
                                    <LowPriorityIcon sx={{ mr: 1 }} />
                                    一覧へ戻る
                                </GrayButton>
                                : null
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {contractType ?
                    <Grid item md={6} lg={6} sx={{ mt: 3 }} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: 28, ml: 2.5 }}>
                            {contractType}
                        </Typography>
                    </Grid> : null
                }
                {status ?
                    <PointUpBackGround item md={6} lg={6} sx={{ mt: 3 }}
                        style={{
                            backgroundColor: "#efffe0",
                        }}
                    >
                        <Typography sx={{ fontSize: 20, m: 1 }}>
                            {status === "お客様署名中" ?
                                status + "　" + (signaturesRequireNumber ? signaturesRequireNumber : null)
                                : status
                            }
                        </Typography>
                    </PointUpBackGround> : null
                }
                {constructionName ?
                    <Grid container sx={{ ml: 6.5, mt: 3 }}>
                        <PointUpBackGround item xs={12}
                            style={{
                                backgroundColor: "#ffffff", border: "1px solid", borderColor: "#e9e9e9",
                            }}
                        >
                            <Typography sx={{ fontSize: 20, m: 1 }}>
                                {constructionName}
                            </Typography>
                        </PointUpBackGround>
                    </Grid> : null
                }
            </Grid>
            <Grid item xs={12}>
                <DigitalConstractUploadFrame />
            </Grid>
            <Grid item xs={12}>
                <DigitalConstractUpdateFrame />
            </Grid>
        </ConstractContents >
    );
}