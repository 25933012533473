import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import useAuthAxios from "../../../useAuthAxios";
import { DigitalConstractsContext } from "../../../DigitalConstracts";
import Tooltip from '@mui/material/Tooltip';

import SearchIcon from "@mui/icons-material/Search";
import { useForm, Controller } from "react-hook-form";
import { ConstractContents, CautionButton, GrayButton, NomalButton } from '../../css/CSS.js';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import DigitalConstractListDelConfirm from './DigitalConstractListDelConfirm';
import ListIcon from '@mui/icons-material/List';
import LoadingDialog from '../../Loading';
import { setCookie, getCookie, removeCookie } from "../../Action/HandleCookie"
import DownloadIcon from '@mui/icons-material/Download';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const TableFlame = styled(TableContainer)({
    borderRadius: "10px 10px 0 0",
});
const Header = styled(TableRow)({
    backgroundColor: "#289bdf",
});
const Text = styled(Typography)({
    color: "#FFFFFF",
    transform: "scale(0.9)",
});

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
    {
        id: 'icon',
        label: '',
        align: 'left'
    },
    {
        id: 'kojiCD',
        label: '工事コード',
        align: 'left'
    },
    {
        id: 'constructionName',
        label: '邸名',
        align: 'left'
    },

    {
        id: 'contractType',
        label: '契約書種類',
        align: 'left'
    },
    {
        id: 'status',
        label: '状態',
        align: 'left'
    },
    {
        id: 'statusUpdateDate',
        label: '変更日時',
        align: 'left'
    },
    {
        id: 'PDFDownload',
        label: '',
        align: 'left'
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <Header sx={{ backgroundColor: props.mode === true ? "#C0C0C0" : "#289bdf" }}>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={headCell.id === 'kojiCD' ? { pr: 0, pl: 1.5 } : { pr: 0, pl: 0 }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Text sx={{ color: props.mode === true ? "#000000" : "#FFFFFF" }}>{headCell.label}</Text>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </Header>
        </TableHead >
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number,
};

export default function ConfirmationList() {
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth"), };
    const {
        kojiCD, setKojiCD, seqNo, setSeqNo, setContractName, contractType, setContractType, setStatus, setStatusCheck, setFromList
    } = useContext(DigitalConstractsContext);

    //テーブルヘッダー、フッター処理
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCookie("ConstractsListViewPerPage", String(event.target.value));
        setPage(0);
    };

    React.useEffect(() => {
        if (getCookie("ConstractsListViewPerPage") != false) {
            setRowsPerPage(parseInt(getCookie("ConstractsListViewPerPage"), 10))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCookie("ConstractsListViewPerPage")]);

    const authAxios = useAuthAxios();
    const [list, setList] = useState()
    const { control, handleSubmit, getValues, setValue, watch } = useForm({
        defaultValues: {
            kojiCD: "",
            constructionName: "",
            changeDateWithin: 14,
            concluded:true,
        },
        mode: 'onChange'
    });

    const navigate = useNavigate();
    const [deleteMode, setDeleteMode] = useState(false);

    const onSubmit_ChangeDeleteMode = () => {
        setDeleteMode(true)
    };
    const onSubmit_ChangeListMode = () => {
        setDeleteMode(false)
    };

    ////ブラウザバックで構成画面から一覧へ戻った場合、Cookieから工事情報を削除する
    React.useEffect(() => {
        localStorage.removeItem('URLparams')
    }, []);

    

    const [toEdit, setToEdit] = useState(false);
    const ChangeEdit = (kojiCD, seqNo, constructionName, contractType, status) => {
        setKojiCD(kojiCD)
        setSeqNo(seqNo)
        setContractName(constructionName)
        setContractType(contractType)
        setStatus(status)
        setToEdit(true)
    }

    React.useEffect(() => {
        if (toEdit === true) {
            navigate("/digitalconstract?kojiCD=" + kojiCD + "&costructtype=" + (contractType === "工事請負契約書" ? "base" : "modfy") + "&seqno=" + seqNo);
            //setStatusCheck(true)
            setFromList(true)
            setToEdit(false)
        }
    }, [toEdit]);

    const [processing_setList, setProcessing_setList] = useState(false);
    React.useEffect(() => {
        setProcessing_setList(true)
        setFromList(false)
        authAxios
            .get('api/contracts/contracts?changeDateWithin=14&concluded=1', { headers })
            .then(response => {
                if (response.status === 200) {
                    const listdata = JSON.parse((JSON.stringify(response.data)))
                    setPage(0)
                    setList(listdata.contractList)
                }
                setProcessing_setList(false)
            }).catch((error) => {
                if (error.response.status === 401) {
                    alert("認証情報が正しくありません。");
                    navigate("/");
                } else {
                    alert("請負契約書一覧情報の取得に失敗しました。");
                }
                setProcessing_setList(false)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [valueChangeDateWithin, setValueChangeDateWithin] = useState('14');
    const handleChange_changeDateWithin = (event) => {
        const newValue = event.target.value;
        if (/^\d*$/.test(newValue)) {
            if (/^0+$/.test(newValue)) {
                newValue = 0;
            }
            setValueChangeDateWithin(newValue);
        }
    };

    const [checked, setChecked] = useState(true);
    const [checkedConcluded, setCheckedConcluded] = useState(1);

    const onClick_concluded = (event) => {
        setChecked(event.target.checked);
    };

    React.useEffect(() => {
        if (checked) {
            setCheckedConcluded(1);
        } else {
            setCheckedConcluded(0);
        }
    }, [checked]);

    const [processing_search, setProcessing_search] = useState(false);
    const onSubmit_Search = () => {
        setProcessing_search(true)
        authAxios
            .get('api/contracts/contracts?kojiCD=' + getValues("kojiCD") + "&constructionName=" + getValues("constructionName")
            + "&changeDateWithin=" + valueChangeDateWithin + "&concluded=" + checkedConcluded, { headers })
            .then((response) => {
                if (response.status === 200) {
                    const listdata = JSON.parse((JSON.stringify(response.data)))
                    setPage(0)
                    setList(listdata.contractList)
                }
                setProcessing_search(false);
            }).catch((error) => {
                if (error.response.status === 401) {
                    alert("認証情報が正しくありません。");
                    navigate("/");
                } else {
                    alert("絞り込み検索に失敗しました。");
                }
                setProcessing_search(false);
            })
        setTimeout(() => {
            setProcessing_search(false);
        }, 10000);
    };

    const [processing_searchReset, setProcessing_searchReset] = useState(false);
    const onSubmit_Reset = () => {
        setProcessing_searchReset(true)
        authAxios
            .get('api/contracts/contracts' + "?changeDateWithin=14&concluded=1", { headers })
            .then(response => {
                if (response.status === 200) {
                    const listdata = JSON.parse((JSON.stringify(response.data)))
                    setPage(0)
                    setList(listdata.contractList)
                    setValue("kojiCD", "")
                    setValue("constructionName", "")
                    setValueChangeDateWithin(14)
                    setChecked(true)
                }
                setProcessing_searchReset(false)
            }).catch((error) => {
                if (error.response.status === 401) {
                    alert("認証情報が正しくありません。");
                    navigate("/");
                } else {
                    alert("検索条件リセットに失敗しました。");
                }
                setProcessing_searchReset(false)
            });
        setTimeout(() => {
            setProcessing_searchReset(false);
        }, 10000);
    };

    const [Confirmation, setConfirmation] = useState(false);
    const [deleteCheck, setDeleteCheck] = useState(false);
    const onSubmit_Delete = (kojiCD, seqNo, contractType) => {
        setConfirmation(true)
        setKojiCD(kojiCD)
        setSeqNo(seqNo)
        setContractType(contractType === "工事請負契約書" ? "base" : contractType === "変更工事請負契約書" ? "modfy" : null)
    };

    React.useEffect(() => {
        if (deleteCheck === true) {
            authAxios
                .delete('api/contracts/' + contractType + "/" + kojiCD + "/" + seqNo, { headers })
                .then(response => {
                    if (response.status === 200) {
                        setDeleteCheck(false)
                        setConfirmation(false)
                        setProcessing_setList(true)
                        authAxios
                            .get('api/contracts/contracts')
                            .then(response => {
                                if (response.status === 200) {
                                    const listdata = JSON.parse((JSON.stringify(response.data)))
                                    setPage(0)
                                    setList(listdata.contractList)
                                }
                                setProcessing_setList(false)
                            }).catch((error) => {
                                if (error.response.status === 401) {
                                    alert("認証情報が正しくありません。");
                                    navigate("/");
                                } else {
                                    alert("一覧情報取得に失敗しました。");
                                }
                                setProcessing_setList(false)
                            });
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("契約書の削除に失敗しました。");
                    }
                    setProcessing_setList(false)
                    setDeleteCheck(false)
                    setConfirmation(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteCheck]);


    const [selectContractType, setSelectContractType] = useState();
    const [selectKojiCD, setSelectKojiCD] = useState();
    const [selectSeqNo, setSelectSeqNo] = useState();
    const [selectStatus, setSelectStatus] = useState();
    const [getPDFFiles, setGetPDFFiles] = useState(false);

    const getFiles = (contractType, kojiCD, seqNo, status) => {
        setSelectKojiCD(kojiCD)
        setSelectSeqNo(seqNo)
        setSelectContractType(contractType === "工事請負契約書" ? "base" : "modfy")
        setSelectStatus(status === "お客様承認済み(契約締結)" ? "?certificate=true" : "?certificate=false")
        setGetPDFFiles(true)
    }

    React.useEffect(() => {
        if (getPDFFiles === true) {
            authAxios
                .get(
                    "api/contracts/" +
                    selectContractType +
                    "/" +
                    selectKojiCD +
                    "/" +
                    selectSeqNo +
                    "/allfiles" +
                    selectStatus,
                    { headers, responseType: "blob" }
                )
                .then((response) => {
                    if (response.status === 200) {
                        const disposition = response.headers['content-disposition'];
                        let filename = '';
                        if (disposition && disposition.indexOf('attachment') !== -1) {
                            const matches = disposition.match(/filename[^;]*;/);
                            if (matches && matches.length > 0) {
                                filename = matches[0].replace(/filename[^;]*;\s*/, '');
                            }
                            const encodedFilenameMatches = disposition.match(/filename\*=UTF-8''(.+)/);
                            if (encodedFilenameMatches && encodedFilenameMatches.length > 1) {
                                filename = decodeURI(encodedFilenameMatches[1]);
                            }
                        }
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                            "download",
                            decodeURIComponent(filename)
                        );
                        document.body.appendChild(link);
                        link.click();
                        setProcessing_setList(false);
                        setGetPDFFiles(false);
                    }
                })
                .catch((response) => {
                    alert("PDFのダウンロードに失敗しました。", response.body);
                    setProcessing_setList(false);
                    setGetPDFFiles(false);
                });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPDFFiles]);

    return (
        <ConstractContents style={{ marginLeft: 0, marginRight: 0 }} maxWidth="xl">
            <DigitalConstractListDelConfirm
                open={Confirmation} contractType={contractType} kojiCD={kojiCD} setDeleteCheck={setDeleteCheck} setConfirmation={setConfirmation}
            />
            <form onSubmit={handleSubmit()} >
                <LoadingDialog load={processing_setList} />
                <Grid container rowSpacing={{ xs: "auto" }} columnSpacing={3}>
                    <Grid item xs="auto">
                        <SearchIcon />
                        <Controller
                            control={control}
                            name="kojiCD"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="kojiCD"
                                    label="工事コード"
                                    variant="standard"
                                    sx={{ width: 80}}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs="auto" >
                        <SearchIcon />
                        <Controller
                            control={control}
                            name="constructionName"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="constructionName"
                                    label="邸名"
                                    variant="standard"
                                    sx={{ width: 300 }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs="auto" >
                        <Grid container>
                            <Grid item xs="auto" >
                                <SearchIcon />
                                <Controller
                                    control={control}
                                    name="changeDateWithin"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            id="changeDateWithin"
                                            label="変更日時"
                                            variant="standard"
                                            sx={{ width: 70, ml: 1 }}
                                            inputProps={{ maxLength: 3, style: { textAlign: 'center' } }}
                                            defaultValue="14"
                                            value={valueChangeDateWithin}
                                            onChange={handleChange_changeDateWithin}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs="auto" >
                                <Typography sx={{ pt: 2.4 }}>日以内</Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs="auto" >
                        <Controller
                            control={control}
                            name="concluded"
                            render={({ field }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.0)", pt: 1.4, }}
                                    control={
                                        <Checkbox
                                            {...field}
                                            defaultChecked
                                            checked={checked}
                                            onClick={onClick_concluded}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography >締結済みを含む</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs="auto" >
                        <NomalButton
                            className="confButton"
                            type="submit"
                            onClick={() => onSubmit_Search()}
                            disabled={processing_search}
                        ><SearchIcon sx={{ mr: 1 }} />
                            {processing_search ? "検索中" : "検索"}
                        </NomalButton>
                        <LoadingDialog load={processing_search} />
                    </Grid>
                    <Grid item xs="auto">
                        <CautionButton
                            type="button"

                            disabled={processing_searchReset}
                            onClick={() => onSubmit_Reset()}
                        ><RefreshIcon sx={{ mr: 1 }} />
                            {processing_searchReset ? "リセット中" : "検索条件リセット"}
                        </CautionButton>
                        <LoadingDialog load={processing_searchReset} />
                    </Grid>
                    {localStorage.getItem("contractDelete") === "1" ?
                        <Grid item xs="auto" >
                            {deleteMode === true ?
                                <NomalButton
                                    type="button"
                                    onClick={() => onSubmit_ChangeListMode()}

                                > <ListIcon sx={{ mr: 1 }} />一覧モード
                                </NomalButton>
                                :
                                <GrayButton
                                    type="button"
                                    onClick={() => onSubmit_ChangeDeleteMode()}

                                > <DeleteIcon color="disabled" sx={{ mr: 1 }} />削除モード
                                </GrayButton>
                            }
                        </Grid> : null
                    }
                </Grid>
            </form>
            <Paper sx={{ width: '100%', mt: 5, boxShadow: 0 }} >
                <TableFlame>
                    <Table
                        align="center"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={list?.length}
                            mode={deleteMode}
                        />
                        <TableBody>
                            {stableSort(list, getComparator(order, orderBy))
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                ?.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            sx={{ backgroundColor: index % 2 === 0 ? "#F4F5F7" : "#EEEEEE" }}
                                        >
                                            {deleteMode === true ?
                                                <TableCell align="left" width="5%" sx={{ pr: 0 }}><Button onClick={() => onSubmit_Delete(row.kojiCD, row.seqNo, row.contractType)}><DeleteIcon color="disabled" /></Button></TableCell>
                                                :
                                                <TableCell align="left" width="8%" sx={{ pr: 0 }}><NomalButton onClick={() => ChangeEdit(row.kojiCD, row.seqNo, row.constructionName, row.contractType, row.status)}><EditIcon sx={{ mr: 1 }} />詳細</NomalButton></TableCell>
                                            }

                                            <TableCell align="left" width="9%" sx={{ pr: 0 }}>{row.kojiCD}</TableCell>
                                            <TableCell align="left" width="43%" sx={{ pl: 0.3, pr: 0 }}>{row.constructionName}</TableCell>
                                            <TableCell align="left" width="12%" sx={{ pl: 0.8, pr: 0 }}>{row.contractType}</TableCell>
                                            <TableCell align="left" width="12%" sx={{ pl: 0.8, pr: 0 }}>{row.status}</TableCell>
                                            <TableCell align="left" width="10%" sx={{ pl: 0.0, pr: 0 }}>{row.statusUpdateDate}</TableCell>
                                            <TableCell align="left" width="3%" sx={{ pr: 0 }}>
                                                {deleteMode === false && row.status === "お客様承認済み(契約締結)" ?
                                                    <Tooltip disableFocusListener
                                                        arrow
                                                        title={getPDFFiles === true ? "ファイルダウンロード中です" : "締結済みの書類をダウンロードします"} placement="top">
                                                        <DownloadIcon sx={{ mr: 1 }} onClick={() => getFiles(row.contractType, row.kojiCD, row.seqNo, row.status)} disabled={getPDFFiles} />
                                                    </Tooltip>
                                                    : null}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableFlame>
                {list ?
                    <TablePagination
                        component="div"
                        count={list?.length}
                        labelRowsPerPage="表示件数"
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 30, 50]}
                        page={page}
                        labelDisplayedRows={(from, to, count = list?.length) => (`全${count}中　${from.from}件～${from.to === -1 ? from.count : from.to}件を表示  `)}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    : <Grid />
                }
            </Paper>
        </ConstractContents >
    );
}
